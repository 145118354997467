import React from "react"

export const withAddresses = Component => ({ name = "Addresses", page, uri }) => {
  const { title } = page

  const paths = [
    {
      title,
    },
  ]

  Component.displayName = name
  return <Component uri={uri} paths={paths} />
}
