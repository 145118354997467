import React from "react"

import { withAddressesForm } from "./withAddressesForm"
import { Input } from "../../../Form/Input"
import { Dropdown } from "../../../Form/Dropdown"
import { Form, Header, Control, StateWrapper } from "./AddressFormStyles"
import { CheckboxInput } from "../../../Form/Checkbox/Checkbox"
import { StyledButton } from "../../../Styled/Button"
import { Errors, Error } from "../../Layout/LayoutStyles"

export const AddressesForm = withAddressesForm(
  ({
    address,
    handleChange,
    handleSubmit,
    handleCancel,
    loading,
    errors,
    region,
    additionalSetDefaultAddressButton,
    additionalEditAddressText,
    additionalFirstNamePlaceholder,
    additionalLastNamePlaceholder,
    additionalStreetPlaceholder,
    additionalSuburbPlaceholder,
    additionalStatePlaceholder,
    additionalCountryPlaceholder,
    additionalPostcodePlaceholder,
    additionalSaveButton,
    additionalCancelButton,
  }): JSX.Element => (
    <Form onSubmit={handleSubmit}>
      <Header>{additionalEditAddressText}</Header>
      <Input
        name="firstName"
        type="text"
        value={address?.firstName}
        onChange={handleChange}
        required
        label={additionalFirstNamePlaceholder}
        spacing={"small"}
      />
      <Input
        name="lastName"
        type="text"
        value={address?.lastName}
        onChange={handleChange}
        required
        label={additionalLastNamePlaceholder}
        spacing={"small"}
      />
      <Input
        name="address1"
        type="text"
        value={address?.address1}
        onChange={handleChange}
        required
        label={additionalStreetPlaceholder}
        spacing={"small"}
      />
      <Dropdown
        label={additionalCountryPlaceholder}
        name={"country"}
        value={address?.country}
        handleChange={handleChange}
        options={region?.countries}
        spacing={"small"}
        required
      />
      <Dropdown
        label={additionalStatePlaceholder}
        name={"province"}
        value={address?.province}
        handleChange={handleChange}
        options={region?.provinces}
        spacing={"small"}
        required
        disabled={!address?.country}
      />
      <StateWrapper>
        <Input name="city" type="text" value={address?.city} onChange={handleChange} required label={additionalSuburbPlaceholder} spacing={"small"} />
        <Input name="zip" type="text" value={address?.zip} onChange={handleChange} required label={additionalPostcodePlaceholder} spacing={"small"} />
      </StateWrapper>
      <CheckboxInput name={"default"} checked={address?.default} handleChange={handleChange} title={additionalSetDefaultAddressButton}>
        {additionalSetDefaultAddressButton}
      </CheckboxInput>
      {errors?.length > 0 && (
        <Errors>
          {errors?.map((error, index) => (
            <Error key={index}>
              <p>{error?.message}</p>
            </Error>
          ))}
        </Errors>
      )}
      <Control>
        <StyledButton wide type={`submit`} colour={"yellow"} disabled={loading}>
          {additionalSaveButton}
        </StyledButton>
        <StyledButton type={"button"} wide colour={"yellow"} onClick={() => handleCancel()} disabled={loading}>
          {additionalCancelButton}
        </StyledButton>
      </Control>
    </Form>
  )
)
