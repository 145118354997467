import tw, { styled } from "twin.macro"

import { StyledButton } from "../../../Styled/Button"

export const Outer = styled.div`
  ${tw`border-b border-grey-dark pt-2 pb-2 flex flex-row justify-between`}
  :first-of-type {
    ${tw`border-t border-grey-dark`}
  }
`

export const Inner = tw.div`
  flex flex-col justify-between
`

export const Address = tw.div`
  flex flex-col
`

export const Text = tw.p`
  text-12
`

export const Control = tw.div`
  grid grid-rows-2 gap-y-1
`

export const DefaultBadge = tw.p`
  inline-block text-12 py-0-5 px-1-2 bg-black text-white
`

export const Button = styled(StyledButton)`
  ${tw`px-1-5 py-0-7 border border-grey text-grey`}
`

export const SetDefaultButton = styled(StyledButton)`
  ${tw`py-0-5 bg-grey text-white`}
`
