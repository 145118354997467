import tw, { styled } from "twin.macro"
import { Heading12 } from "../../../Styled/Text"

export const Return = styled.button`
  ${tw`w-full flex flex-row items-center justify-center md:justify-start mb-1`}
  span {
    ${tw`text-12 text-red`}
  }
`

export const Form = tw.form`
  
`

export const Header = styled(Heading12)`
  ${tw`mb-1`}
`

export const Control = styled.div`
  ${tw`grid grid-cols-2 gap-x-1-6 my-2`}
  button {
    ${tw`px-1`}
  }
`

export const StateWrapper = tw.div`
  grid grid-cols-2 gap-x-1-6
`
