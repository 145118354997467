import React from "react"

import { withAddressesItem } from "./withAddressesItem"
import { Outer, Inner, Address, Text, Control, DefaultBadge, Button, SetDefaultButton } from "./AddressesItemStyles"

export const AddressesItem = withAddressesItem(
  ({
    address,
    handleDelete,
    handleEdit,
    handleSetDefault,
    loading,
    additionalEditAddressButton,
    additionalDeleteAddressButton,
    additionalSetDefaultAddressButton,
    additionalDefaultBadge,
  }): JSX.Element => (
    <Outer>
      <Inner>
        <Address>
          <Text>{address.address1}</Text>
          <Text>
            {address.city} {address.provinceCode} {address.country}
          </Text>
          <Text>{address.zip}</Text>
        </Address>
        <div>
          {address.default ? (
            <DefaultBadge>{additionalDefaultBadge}</DefaultBadge>
          ) : (
            <SetDefaultButton onClick={() => handleSetDefault(address?.id)} title={additionalSetDefaultAddressButton}>
              <span>{additionalSetDefaultAddressButton}</span>
            </SetDefaultButton>
          )}
        </div>
      </Inner>
      <Control>
        <Button colour={"transparent"} title={additionalEditAddressButton} onClick={() => handleEdit(address?.id)} disabled={loading}>
          {additionalEditAddressButton}
        </Button>
        <Button colour={"transparent"} title={additionalDeleteAddressButton} onClick={() => handleDelete(address?.id)} disabled={loading}>
          {additionalDeleteAddressButton}
        </Button>
      </Control>
    </Outer>
  )
)
